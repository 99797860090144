import { useEffect, useLayoutEffect } from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { LuBookTemplate } from 'react-icons/lu';
import { RxDashboard, RxExit, RxFileText } from 'react-icons/rx';

import Navbar from 'src/components/navbar';
import Sidebar from 'src/components/sidebar';

import useAuth from 'src/hooks/useAuth';
import useBreakpoint from 'src/hooks/useBreakpoint';
import usePermissions from 'src/hooks/usePermissions';
import useToggle from 'src/hooks/useToggle';

import Toggler from './components/toggler';

import styles from './styles.module.scss';

const DEFAULT_PROFILE =
  'https://firebasestorage.googleapis.com/v0/b/makecv-e2854.appspot.com/o/assets%2Fundraw_pic_profile_re_7g2h.svg?alt=media';

type MainLayoutProps = {
  children: React.ReactNode;
};

const MainLayout = ({ children }: MainLayoutProps) => {
  const { user, signOut } = useAuth();
  const { canCreateTemplate } = usePermissions();

  const { gt } = useBreakpoint();

  const { value: sidebarOpen, on: showSidebar, off: hideSidebar } = useToggle(false);

  const { value: collapsed, toggle: toggleCollapsed, on: collapse, off: expand } = useToggle(false);

  useEffect(() => {
    document.documentElement.dataset.collapsed = String(collapsed);

    window.addEventListener('layout::main::collapse', collapse);
    window.addEventListener('layout::main::expand', expand);

    return () => {
      window.removeEventListener('layout::main::collapse', collapse);
      window.removeEventListener('layout::main::expand', expand);
    };
  }, [collapsed, collapse, expand]);

  useLayoutEffect(() => {
    if (sidebarOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.querySelector('#root')?.setAttribute('aria-hidden', 'true');
    } else {
      document.body.style.overflowY = 'auto';
      document.body.querySelector('#root')?.removeAttribute('aria-hidden');
    }
  }, [sidebarOpen]);

  return (
    <div
      className={clsx(styles['main-layout'], {
        [styles.open]: sidebarOpen
      })}
    >
      <div className={styles.backdrop} onClick={hideSidebar} />

      <aside className={styles.side}>
        <Sidebar collapsed={collapsed && gt.sm}>
          <Sidebar.Profile avatar={user?.photoURL || DEFAULT_PROFILE} name={user?.displayName} />

          <Sidebar.List className={styles.grow}>
            <Sidebar.Item as={NavLink} to="/" Icon={RxDashboard} onClick={hideSidebar}>
              Dashboard
            </Sidebar.Item>
            <Sidebar.Item as={NavLink} to="/my-resumes" Icon={RxFileText} onClick={hideSidebar}>
              My Resumes
            </Sidebar.Item>

            {canCreateTemplate && (
              <Sidebar.Item as={NavLink} to="/admin/builder" Icon={LuBookTemplate} onClick={hideSidebar}>
                Templates
              </Sidebar.Item>
            )}
          </Sidebar.List>

          <Sidebar.List>
            <Sidebar.Item as="button" Icon={RxExit} onClick={signOut}>
              Sign out
            </Sidebar.Item>
          </Sidebar.List>
        </Sidebar>

        <Toggler className={styles.toggler} aria-label="Toggle sidebar" onClick={toggleCollapsed} />
      </aside>

      <Navbar className={styles.nav}>
        <Navbar.Toggle onClick={showSidebar} />
        <Navbar.Profile avatar={user?.photoURL || DEFAULT_PROFILE} name={user?.displayName} />
      </Navbar>

      <main className={styles.main}>{children}</main>
    </div>
  );
};

export default MainLayout;
